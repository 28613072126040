body {
  background-color: #A0C49D;
}
#root {
  /* Phone */
  position: relative;
  min-height: 125vh;
}
@media (min-width: 768px) {
  #root {
    /* Small Screen and up */
    min-height: 100vh;
  }
}
.main {
  padding-bottom: 45vh;
}
@media (min-width: 480px) {
  .main {
    padding-bottom: 30vh;
  }
}
@media (min-width: 768px) {
  .main {
    padding-bottom: 25vh;
  }
}
@media (min-width: 2000px) {
  .main {
    padding-bottom: 15vh;
  }
}
.title {
  color: #364734;
  display: inline-flex;
  align-content: center;
}
.card {
  max-width: 95%;
  margin: 3%;
  padding: 3%;
  background-image: linear-gradient(to bottom right, #E1ECC8, #F7FFE5);
  display: inline-block;
  align-items: center;
}
.card .cardName {
  width: 49%;
  color: #364734;
  display: inline-block;
  margin: 0px;
}
.card .cardType {
  width: 49%;
  color: #364734;
  text-align: right;
  float: right;
  margin: 0px;
}
.card p {
  display: block;
  color: #364734;
}
@media (min-width: 480px) {
  .card {
    max-width: 48%;
    margin: 0.5%;
    padding: 0.5%;
  }
}
@media (min-width: 800px) {
  .card {
    max-width: 31%;
    margin: 0.5%;
    padding: 0.5%;
  }
}
@media (min-width: 1200px) {
  .card {
    max-width: 24%;
    margin: 0.25%;
    padding: 0.25%;
  }
}
.charform {
  padding-left: 0.5%;
  margin-bottom: 0.5%;
  color: #364734;
}
.Logo {
  width: 90px;
  height: 75px;
}
footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  color: #364734;
  font-size: 0.75em;
}
@media (min-width: 1200px) {
  footer {
    font-size: 1em;
  }
}
